.embla {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: calc(100vh - 65px);
}

.embla__viewport { overflow: hidden; }

.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    position: relative;
}

.embla__parallax { height: 100%; overflow-x : hidden; }
.embla__parallax__layer {
    position: relative;
    height: 100%;
    width: 100%;
}

.embla__dot {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
}

.embla__dots {
    z-index: 1;
    bottom: 1.6rem;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.embla__dot {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.embla__dot:after {
    background: #fff;
    border: 1px solid #000;
    border-radius: 0.2rem;
    width: 100%;
    height: 0.4rem;
    content: '';
}

.embla__dot--selected:after { background: #6af; }
